import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3"
import { Video } from "gatsby-video"
import poster_image from "../../assets/videos/wingamm/wingamm-poster.jpg"

const Oasi540PlumbingSystem = () => {
  const video = useStaticQuery(graphql`
    query {
      file(
        relativePath: { eq: "wingamm/Oasi540PlumbingSystem.mp4" }
        sourceInstanceName: { eq: "videos" }
      ) {
        childVideoFfmpeg {
          webm: transcode(
            outputOptions: ["-crf 20", "-b:v 0", "-acodec copy"]
            maxWidth: 900
            maxHeight: 480
            fileExtension: "webm"
            codec: "libvpx-vp9"
          ) {
            width
            src
            presentationMaxWidth
            presentationMaxHeight
            originalName
            height
            fileExtension
            aspectRatio
          }
          mp4: transcode(
            maxWidth: 900
            maxHeight: 480
            fileExtension: "mp4"
            codec: "libx264"
          ) {
            width
            src
            presentationMaxWidth
            presentationMaxHeight
            originalName
            height
            fileExtension
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <div>
            {modal ? (
              <Link to={closeTo}>Close</Link>
            ) : (
              <header>
                <h1>Website Title</h1>
              </header>
            )}
          </div>
          <Video
            poster={poster_image}
            autoPlay
            loop
            sources={[
              video.file.childVideoFfmpeg.webm,
              video.file.childVideoFfmpeg.mp4,
            ]}
          />
        </>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default Oasi540PlumbingSystem
